import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs as MUIBreadcrumbs, Typography } from '@mui/material'
import Link from 'next/link'

export type BreadcrumbsProps = {
  breadcrumbs?: {
    text: string
    path?: string
  }[]
}

const createBreadcrumbsLink = ({ breadcrumbs }: BreadcrumbsProps) =>
  breadcrumbs?.map((row) => {
    if (typeof row.path !== 'undefined') {
      return (
        <Link key={row.text} href={row.path} style={{ color: '#5E86E5' }}>
          {row.text}
        </Link>
      )
    }
    return (
      <Typography key={row.text} color="text.primary">
        {row.text}
      </Typography>
    )
  })

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  const breadcrumbsLinkList = createBreadcrumbsLink({ breadcrumbs })
  return (
    <MUIBreadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbsLinkList}
    </MUIBreadcrumbs>
  )
}
