import '@/styles/globals.css'
import { CacheProvider } from '@emotion/react'
import { ThemeProvider } from '@mui/material'
import { GoogleTagManager } from '@next/third-parties/google'
import Head from 'next/head'
import { SessionProvider } from 'next-auth/react'
import { useState } from 'react'

import type { AppCustomProps } from '@/types/common'
import type { Session } from 'next-auth'

import { AdminAuth } from '@/AdminAuth'
import { ErrorBoundary } from '@/components/organisms/error/ErrorBoundary'
import { AdminMenu } from '@/components/templates/admin/AdminMenu'
import {
  UnauthenticatedMenu,
  UserMenu,
} from '@/components/templates/user/UserMenu'
import { DialogsContainer } from '@/components/uiParts/Dialog'
import { AUTHORITY_ADMIN } from '@/constants/constants'
import { DialogContextProvider } from '@/contexts/Dialog'
import { FormContextProvider } from '@/contexts/Form'
import { LoadingProvider } from '@/contexts/Loading'
import { SnackbarContextProvider } from '@/contexts/Snackbar'
import createEmotionCache from '@/createEmotionCache'
import { RefreshTokenHandler } from '@/RefreshTokenHandler'
import { Snackbar } from '@/Snackbar'
import AdminTheme from '@/styles/admin/theme'
import UserTheme from '@/styles/user/theme'
import { SwrWrapper } from '@/SwrWrapper'
import { UserAuth } from '@/UserAuth'

const clientSideEmotionCache = createEmotionCache()

// TODO:AdminAuthとUserAuthを統一できないか検討する。権限が増えた時に対応できないため。
const App = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps: { session, ...pageProps },
}: AppCustomProps) => {
  const [interval, setInterval] = useState(0)
  return (
    <CacheProvider value={emotionCache}>
      <SessionProvider session={session as Session} refetchInterval={interval}>
        <LoadingProvider>
          <ErrorBoundary>
            <SnackbarContextProvider>
              <SwrWrapper>
                <DialogContextProvider>
                  <>
                    {Component.property?.title && (
                      <Head>
                        <title>{Component.property?.title}</title>
                      </Head>
                    )}
                    {process.env.NODE_ENV === 'production' && (
                      <GoogleTagManager
                        gtmId={
                          process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || ''
                        }
                      />
                    )}
                    {
                      // 認証が必要な場合
                      (Component.property?.auth && (
                        <FormContextProvider>
                          {
                            // Admin権限がある場合
                            Component.property.auth.role === AUTHORITY_ADMIN ? (
                              <AdminAuth
                                is_portrich={
                                  Component.property.auth.is_portrich
                                }
                              >
                                <ThemeProvider theme={AdminTheme}>
                                  <AdminMenu
                                    breadcrumbs={Component.property.breadcrumbs}
                                  >
                                    <Component {...pageProps} />
                                  </AdminMenu>
                                  <DialogsContainer />
                                  <Snackbar />
                                </ThemeProvider>
                              </AdminAuth>
                            ) : (
                              // Admin権限がない場合
                              <UserAuth>
                                <ThemeProvider theme={UserTheme}>
                                  <UserMenu
                                    breadcrumbs={Component.property.breadcrumbs}
                                  >
                                    <Component {...pageProps} />
                                  </UserMenu>
                                  <DialogsContainer />
                                  <Snackbar />
                                </ThemeProvider>
                              </UserAuth>
                            )
                          }
                        </FormContextProvider>
                      )) || (
                        // 認証が必要ない場合
                        <ThemeProvider theme={UserTheme}>
                          <UnauthenticatedMenu>
                            <Component {...pageProps} />
                            <DialogsContainer />
                            <Snackbar />
                          </UnauthenticatedMenu>
                        </ThemeProvider>
                      )
                    }
                    <RefreshTokenHandler setInterval={setInterval} />
                  </>
                </DialogContextProvider>
              </SwrWrapper>
            </SnackbarContextProvider>
          </ErrorBoundary>
        </LoadingProvider>
      </SessionProvider>
    </CacheProvider>
  )
}

export default App
