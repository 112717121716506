import { Box } from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { UserItemPopper } from '@/components/organisms/common/Header/UserItemPopper'
import { NotificationWidget } from '@/components/organisms/common/Notification'
import { AppBar } from '@/components/organisms/user/header/AppBar'
import { AppBarProps } from '@/components/organisms/user/header/AppBar/hooks'
import {
  StyledHeaderContainer,
  StyledHeaderRoot,
} from '@/components/organisms/user/header/style'
import { StackRow } from '@/components/uiParts/Stack/Row'
import { USER_ROOT_URL } from '@/constants/constants'
import PortrichIcon from '@/icons/portrich_logo_white.svg'
import { WithResourceType, usePermission } from '@/permissions'

const appBarItems: WithResourceType<AppBarProps>[] = [
  {
    label: 'ダッシュボード',
    path: '/user/dashboard',
    resourceType: 'DASHBOARD',
  },
  { label: '見積', path: '/user/quotes/ocean', resourceType: 'QUOTE_REQUEST' },
  { label: 'ブッキング', path: '/user/booking', resourceType: 'BOOKING' },
  { label: 'シップメント', path: '/user/shipment', resourceType: 'SHIPMENT' },
  { label: '請求書', path: '/user/billing', resourceType: 'BILLING' },
  {
    label: 'ネットワーク',
    path: '#',
    items: [
      { label: '取引先', path: '/user/clients' },
      { label: 'ゲスト会社', path: '/user/guests' },
    ],
    resourceType: 'NETWORK',
  },
]

export const Header = () => {
  const router = useRouter()
  const { filter } = usePermission()

  return (
    <StyledHeaderRoot
      id="portrich-global-header"
      className="wovn-embedded-widget"
    >
      <StyledHeaderContainer>
        <StackRow spacing={4}>
          <Link
            href={USER_ROOT_URL}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <PortrichIcon style={{ height: 18 }} />
          </Link>
          <AppBar appBarItems={filter(appBarItems)} />
        </StackRow>
        {/* {AppConfig().appEnv !== 'production' && (
        <Box sx={{ fontSize: 14, fontWeight: 'bold' }}>
          Develop or Test environment
        </Box>
      )} */}
        <StackRow>
          <Box className="wovn-embedded-widget-anchor" />
          <NotificationWidget
            onClick={(room) => router.push(`/user/shipment/${room.shipment}`)}
          />
          <UserItemPopper />
        </StackRow>
      </StyledHeaderContainer>
    </StyledHeaderRoot>
  )
}

export const UnauthenticatedHeader = () => (
  <StyledHeaderRoot
    id="portrich-global-header"
    className="wovn-embedded-widget"
  >
    <StyledHeaderContainer>
      <PortrichIcon style={{ height: 18 }} />
      {/* {AppConfig().appEnv !== 'production' && (
        <Box sx={{ fontSize: 14, fontWeight: 'bold' }}>
          Develop or Test environment
        </Box>
      )} */}
    </StyledHeaderContainer>
  </StyledHeaderRoot>
)
