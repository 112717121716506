/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

/**
 * * `EXW` - EXW
* `FCA` - FCA
* `FAS` - FAS
* `FOB` - FOB
* `CFR` - CFR
* `CIF` - CIF
* `CPT` - CPT
* `CIP` - CIP
* `DAT` - DAT
* `DPU` - DPU
* `DAP` - DAP
* `DDP` - DDP
 */
export type IncotermsEnum = typeof IncotermsEnum[keyof typeof IncotermsEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IncotermsEnum = {
  EXW: 'EXW',
  FCA: 'FCA',
  FAS: 'FAS',
  FOB: 'FOB',
  CFR: 'CFR',
  CIF: 'CIF',
  CPT: 'CPT',
  CIP: 'CIP',
  DAT: 'DAT',
  DPU: 'DPU',
  DAP: 'DAP',
  DDP: 'DDP',
} as const;
