/**
 * Generated by orval v6.20.0 🍺
 * Do not edit manually.
 * portrich-api
 * OpenAPI spec version: 0.0.0
 */

export type QuoteRequestsMarineTransportListIncoterms = typeof QuoteRequestsMarineTransportListIncoterms[keyof typeof QuoteRequestsMarineTransportListIncoterms];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuoteRequestsMarineTransportListIncoterms = {
  CFR: 'CFR',
  CIF: 'CIF',
  CIP: 'CIP',
  CPT: 'CPT',
  DAP: 'DAP',
  DAT: 'DAT',
  DDP: 'DDP',
  DPU: 'DPU',
  EXW: 'EXW',
  FAS: 'FAS',
  FCA: 'FCA',
  FOB: 'FOB',
} as const;
