import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface Palette {
    border: Palette['primary']
    softOrange: Palette['primary']
    lightGrayishBlue: Palette['primary']
    btnPrimary: Palette['primary']
  }
  interface PaletteOptions {
    border?: PaletteOptions['primary']
    softOrange?: PaletteOptions['primary']
    lightGrayishBlue?: PaletteOptions['primary']
    btnPrimary?: PaletteOptions['primary']
  }
}

export default createTheme({
  palette: {
    primary: { main: '#42526e' },
    secondary: { main: '#2E3E52', light: '#485E7A' },
    base: { main: '#F6F7FC', dark: '#F2F3F5', light: '#F2F2F7' },
    border: { main: '#D6D3D0' },
    alert: { main: '#E06242', light: '#fdeded' },
    text: { primary: '#384143', secondary: '#706D65', disabled: '#C1BDB7' },
    softOrange: { main: '#E6BF64' },
    lightGrayishBlue: { main: '#E9F4FD', light: '#F2F1F0' },
    btnPrimary: { main: '#0DA9F4' },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        color: 'text.primary',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
})
